<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <b-overlay
                    variant="white"
                    :show="showLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <div class="p-1">
                        <form class="" @submit.prevent="submitForm">
                            <div class="row">
                                <div class="form-group col-12 col-md-6">
                                    <label for="phone24">Nombre:</label>
                                    <b-form-input v-model="document_type.type" autocomplete="off"/>
                                    <span v-if="validation_document_type.type" class="text-danger">
                                      {{ validation_document_type.type }}
                                    </span>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                  <label for="phone24">Descripción:</label>
                                  <b-form-input v-model="document_type.description" autocomplete="off"/>
                                  <span v-if="validation_document_type.code" class="text-danger">
                                        {{ validation_document_type.code }}
                                      </span>
                                </div>
                              <div class="form-group col-12 col-md-4 d-flex form-check form-check-inline mt-2 pl-1">
                                <b-form-group class="form-check form-check-inline">
                                  <b-form-checkbox v-model="document_type.to_keeper_contract" :value="1">
                                    DISPONIBLE EN CONTRATO MANTENEDOR
                                  </b-form-checkbox>
                                </b-form-group>
                                <span v-if="validation_document_type.to_keeper_contract"
                                      class="text-danger">{{ validation_document_type.to_keeper_contract }}</span>
                              </div>
                              <div class="form-group col-12 col-md-4 d-flex form-check form-check-inline mt-2 pl-1">
                                <b-form-group class="form-check form-check-inline">
                                  <b-form-checkbox v-model="document_type.to_manual_project_contract" :value="1">
                                    DISPONIBLE EN DOCUMENTACIÓN, PROYECTOS Y MANUALES
                                  </b-form-checkbox>
                                </b-form-group>
                                <span v-if="validation_document_type.to_manual_project_contract"
                                      class="text-danger">{{ validation_document_type.to_manual_project_contract }}</span>
                              </div>

                              <div class="form-group col-12 col-md-4 d-flex form-check form-check-inline mt-2 pl-1">
                                <b-form-group class="form-check form-check-inline">
                                  <b-form-checkbox v-model="document_type.to_emergency" :value="1">
                                    DISPONIBLE EN EMERGENCIA
                                  </b-form-checkbox>
                                </b-form-group>
                                <span v-if="validation_document_type.to_emergency"
                                      class="text-danger">{{ validation_document_type.to_emergency }}</span>
                              </div>

                              <div class="form-group col-12 col-md-4 d-flex form-check form-check-inline mt-2 pl-1">
                                <b-form-group class="form-check form-check-inline">
                                  <b-form-checkbox v-model="document_type.to_legionella" :value="1">
                                    DISPONIBLE EN LEGIONELLA
                                  </b-form-checkbox>
                                </b-form-group>
                                <span v-if="validation_document_type.to_legionella"
                                      class="text-danger">{{ validation_document_type.to_legionella }}</span>
                              </div>

                              <div class="form-group col-12 col-md-4 d-flex form-check form-check-inline mt-2 pl-1">
                                <b-form-group class="form-check form-check-inline">
                                  <b-form-checkbox v-model="document_type.to_maintenance" :value="1">
                                    DISPONIBLE EN MANTENIMIENTOS
                                  </b-form-checkbox>
                                </b-form-group>
                                <span v-if="validation_document_type.to_maintenance"
                                      class="text-danger">{{ validation_document_type.to_maintenance }}</span>
                              </div>

                              <div class="form-group col-12 col-md-4 d-flex form-check form-check-inline mt-2 pl-1">
                                <b-form-group class="form-check form-check-inline">
                                  <b-form-checkbox v-model="document_type.respect_automatic_credentials" :value="1">
                                    REPETAR CREDENCIALES AUTOMÁTICAS
                                  </b-form-checkbox>
                                </b-form-group>
                                <span v-if="validation_document_type.respect_automatic_credentials"
                                      class="text-danger">{{ validation_document_type.respect_automatic_credentials }}</span>
                              </div>
                            </div>
                            <div class="row">
                                <button type="submit" class="btn btn-primary col-12 col-md-1 ml-1 mt-2">
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import {BCard, BCol, BOverlay, BRow, BFormInput, BFormCheckbox, BFormGroup} from 'bootstrap-vue'
import {mapState} from "vuex"
import {GEI_AUXILIAR_DOCUMENTTYPES_LIST} from "@/router/web/constants"
import {createHelpers} from "vuex-map-fields"

import helper from "@/resources/helpers/helpers"

const {mapFields} = createHelpers({
    getterType: 'documentTypes/getField',
    mutationType: 'documentTypes/updateField',
})

export default {
    name: "FormView",
    components: {
      BFormGroup,
      BFormCheckbox,
        BCol,
        BRow,
        BCard,
        BFormInput,
        BOverlay,
    },
    data() {
        return {
            edit: false,
            showLoading: false,
        }
    },
    computed: {
        ...mapState('auxiliariesDocumentTypes', ['validation_document_type']),
        ...mapFields({
            document_type: 'document_type',
        }),
    },
    created() {
        this.$store.commit('auxiliariesDocumentTypes/RESET_STATE')
        this.$store.commit('auxiliariesDocumentTypes/CLEAR_VALIDATION')
        if (this.$route.params.id) {
            this.edit = true
            this.getDocumentTypes()
        } else {
            this.edit = false
        }
        this.$root.$refs.auxiliariesDocumentTypesForm = this
    },
    methods: {
      getDocumentTypes() {
            this.showLoading = true

            this.$store.dispatch('documentTypes/findDocumentTypes', {
                id: this.$route.params.id ?? this.document_type.id
            })
                .then(response => {
                  this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
        cancel() {
            this.$router.push(GEI_AUXILIAR_DOCUMENTTYPES_LIST)
        },
        createAuxiliar() {
            this.$store.dispatch('documentTypes/createDocumentTypes', this.document_type)
                .then(response => {
                    helper.showToast('Tipo de documento creado correctamente!', 'CheckIcon', 'success')
                    this.$router.replace(GEI_AUXILIAR_DOCUMENTTYPES_LIST)
                })
                .catch(error => {
                    helper.showToast('Error al crear tipo de documento', 'AlertOctagonIcon', 'danger')
                    console.log(error)
                })
        },
        updateAuxiliar() {
            this.$store.dispatch('documentTypes/updateDocumentTypes', this.document_type)
                .then(response => {
                    helper.showToast('Tipo de documento editado correctamente!', 'CheckIcon', 'success')
                    this.$router.replace(GEI_AUXILIAR_DOCUMENTTYPES_LIST)
                })
                .catch(error => {
                    helper.showToast('Error al editar tipo de documento', 'AlertOctagonIcon', 'danger')
                    console.log(error)
                })
        },
        submitForm() {
            if (this.$route.params.id) {
                this.updateAuxiliar()
            } else {
                this.createAuxiliar()
            }
        },
    },
}
</script>
